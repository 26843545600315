





































































































































































































































































import { } from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { Loyalty as LoyaltyService, Integrations } from "@/services/SOLO";
import { mapGetters, mapMutations } from "vuex";
import { Select, Option } from "element-ui";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
const ConceptMarketingProps = Vue.extend({
  props: {
    selectedBrands: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => { },
    },
  },
});
export interface Language {
  value: string;
  text: string;
}

@Component({
  components: {
    Translations,
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getSoloLoyalty: 'page/getSoloLoyalty',
      getPage: "page/getPage",
      getDisabledPage: "page/getDisabledPage",
    })
  },
  methods: {
    ...mapMutations({
      setSoloLoyalty: 'page/setSoloLoyalty',
    })
  },
  mixins: [translations],
})
export default class ConceptMarketing extends ConceptMarketingProps {
  translate!: Function;
  public getLocale: any;
  temporaryURL: any = ""
  imageType: Number = 0;
  flagExtention: any = ""
  disImg: any = true;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
  ];
  // fileValue: any = null;
  abandonedCart: Boolean = true;
  // Data and computed properties go here as defined in your original code.

  languages: Language[] = [
    {
      value: 'en-us',
      text: 'English',
    },
    {
      value: 'ar-sa',
      text: 'Arabic',
    },
    {
      value: 'fr',
      text: 'French',
    },
  ];

  languages2: Language[] = [
    {
      value: '',
      text: 'None',
    },
    {
      value: 'en-us',
      text: 'English',
    },
    {
      value: 'ar-sa',
      text: 'Arabic',
    },
    {
      value: 'fr',
      text: 'French',
    },
  ];

  isLanguageSelected(value: string, selectedLanguage: string) {
    return value === selectedLanguage;
  }
  restaurantTypes: Array<Object> = [
    {
      value: 'fastfood',
      text: "Fastfood",
    },
    {
      value: 'burgers',
      text: "Burgers",
    },
    {
      value: 'chicken',
      text: "Chicken",
    },
    {
      value: 'shawarma',
      text: "Shawarma",
    },
    {
      value: 'pizza',
      text: "Pizza",
    },
    {
      value: 'healthy',
      text: "Healthy",
    },
    {
      value: 'coffee',
      text: "Coffee",
    },
    {
      value: 'bakery',
      text: "Bakery",
    },
  ];
  brands: any = [];
  $notify: any;
  validTwitter: Boolean = true;
  validFacebook: Boolean = true;
  validInstagram: Boolean = true;
  validSnapchat: Boolean = true;
  validTiktok: Boolean = true;
  enabledMultipleBranding: Boolean = false;

  getSoloLoyalty!: any;
  getPage!: Array<typeof mapGetters>
  getDisabledPage!: Array<typeof mapGetters>

  private setSoloLoyalty!: Function
  soloLoyalty: any = {};
  newSelectedBrands: any = [];

  @Watch("abandonedCart", { deep: true, immediate: true })
  onAbandonedCartChange(newValue: any) {
    if (!newValue) {
      this.data['abandoned-cart-alert'] = 0;
    }
  }

  @Watch("selectedBrands", { deep: true, immediate: true })
  onChangeSelectedBrands(newValue: any) {
    if (newValue.length) {
      newValue.forEach((brand:any) => {
        this.brands.push({
          id: brand.id,
          label: brand.label,
        })
        this.newSelectedBrands.push(brand.id)
      })
    }
  }

  @Watch("disImg", { deep: true, immediate: true })
  onCheckLogoURI(newValue: any) {
    if (!newValue) {
      if (!newValue['logo-uri']) {
        this.data['logo-uri'] = this.temporaryURL;
      }
      console.log("check url", this.data['logo-uri']);
    }
  }

  @Watch("enabledMultipleBranding", { deep: true, immediate: true })
  onChangeEnabledMultipleBranding(newValue: any) {
    this.data['enabled-multiple-branding'] = newValue
  }

  created() {
    this.checkSoloLoyalty()
  }

  mounted() {
    this.abandonedCart = parseInt(this.data['abandoned-cart-alert']) > 0 ? true : false;
    this.getMerchants()
  }

  onSelectBrands() {
    console.log(this.newSelectedBrands)
    this.data['selected-brands'] = this.newSelectedBrands
  }

  isViewable(menu:any) {
    return this.getPage.includes(menu)
  }

  isDisabled(menu:any) {
    return !this.getDisabledPage.includes(menu)
  }

  checkSoloLoyalty() {
    let provider = 'solo-loyalty';
    Integrations.getSingleIntegration(provider)
      .then((response) => {
        this.setSoloLoyalty(response.data.data[0]);
        this.soloLoyalty = this.getSoloLoyalty

        if (this.soloLoyalty.attributes.options['is-brand'] === "1" || this.soloLoyalty.attributes.options['is-brand'] === 1) {
          this.enabledMultipleBranding = true
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  async getMerchants() {
    try {
      const merchants = await LoyaltyService.getMerchants();

      if (merchants.data.data.length) {
        merchants.data.data.forEach((brand:any) => {
          this.brands.push({
            id: brand.id,
            label: brand.label,
          })
        })
      }
    } catch (error) {
      console.error('Error in getting Error', error)
    }
  }

  removeImage() {
    this.disImg = false;
    this.temporaryURL = this.data['logo-uri'];
    this.data['logo-uri'] = '';
  }

  checkValidUrl(url: string = '', social: string = '') {

    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    setTimeout(() => {
      if (social == 'twitter') {
        /* @ts-ignore */
        if (!url.target._value) {
          this.validTwitter = true;
        } else {
          /* @ts-ignore */
          this.validTwitter = !!pattern.test(url.target._value);
        }
      }
      if (social == 'facebook') {
        /* @ts-ignore */
        if (!url.target._value) {
          this.validFacebook = true;
        } else {
          /* @ts-ignore */
          this.validFacebook = !!pattern.test(url.target._value);
        }
      }
      if (social == 'instagram') {
        /* @ts-ignore */
        if (!url.target._value) {
          this.validInstagram = true;
        } else {
          /* @ts-ignore */
          this.validInstagram = !!pattern.test(url.target._value);
        }
      }
      if (social == 'snapchat') {
        /* @ts-ignore */
        if (!url.target._value) {
          this.validSnapchat = true;
        } else {
          /* @ts-ignore */
          this.validSnapchat = !!pattern.test(url.target._value);
        }
      }
      if (social == 'tiktok') {
        /* @ts-ignore */
        if (!url.target._value) {
          this.validTiktok = true;
        } else {
          /* @ts-ignore */
          this.validTiktok = !!pattern.test(url.target._value);
        }
      }
      /* @ts-ignore */
      console.log('checkValidUrl', social, url.target._value);
    }, 50);
  }


  checkSize(text: any) {
    if (this.getLocale === 'en_US') {
      if (text === 'The image field size must be less than 2000KB') {
        return 'The image file size must be less than 2MB'
      }
    }
    if (text === 'The image field size must be less than 2000KB') {
      return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  filesUploadChange(event: any) {
    if (event.target.files[0]['name'].split('.').pop().toString() == 'png' || event.target.files[0]['name'].split('.').pop().toString() == 'PNG' || event.target.files[0]['name'].split('.').pop().toString() == 'jpg' || event.target.files[0]['name'].split('.').pop().toString() == 'JPG' || event.target.files[0]['name'].split('.').pop().toString() == 'JPEG' || event.target.files[0]['name'].split('.').pop().toString() == 'jpeg') {
      this.disImg = true;
      this.data['logo-uri'] = URL.createObjectURL(event.target.files[0]) ?? '';
      this.data['logo-image'] = event.target.files[0];
      console.log("check data image", event.target.files[0]);
    } else {
      console.log('check ext', event.target.files[0]['name'].split('.').pop().toString());
      let extension = event.target.files[0]['name'].split('.').pop().toString();
      this.$notify({
        title: "FILE EXTENSION WARNING!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Please upload a valid extension! You are about to upload ${extension} extension.`,
        type: "warning",
        icon: "fas fa-bomb",
      });
      return;
    }


    // this.disImg = true;
    //   this.data['logo-uri']= URL.createObjectURL(event.target.files[0]) ?? '';
    //   this.data['logo-image']= event.target.files[0];
  }

}
